<template>
  <div id="topup_view">
    <header-menu/>
    <div class="bigBgcImage">
      <img fetchpriority="high" src="@/assets/imagine/topup/banner_1.png" alt="Alices Image" width="1941" height="294">
    </div>

    <div class="content">
      <topup-login/>
      <!-- <main-content/> -->
      <tab-comp :tabs="tabs"></tab-comp>
    </div>

    <foot-comp/>

    <!--  弹窗提示类  -->
    <alert-msg/>
    <check-order/>
    <loading-data/>
    <checkin-reward ref="checkinReward" @checkIn="onCheckIn"/>
  </div>
</template>

<script>
import axios from "axios";
import TopupLogin from "@/views/topup/child/TopupLogin";
import HeaderMenu from "@/components/HeaderMenu";
import FootComp from "@/components/FootComp";
import AlertMsg from "@/components/AlertMsg";
import CheckOrder from "@/components/CheckOrder";
import LoadingData from "@/components/LoadingData";
import TabComp from "@/components/TabComp.vue";
import CheckinReward from "./CheckinReward.vue";
import basicJson from "@/config";

import { withdrawParamsToObj, openUrl } from "@/util/index";
import { checkPaypalOrderStatus } from "@/request/pay_paypal";
import { checkOrderResultWithXsolla } from "@/request/pay_xsolla";
import { checkOrderResultAirwallex } from "@/request/pay_airwallex";
import { checkOrderResultPayerMax } from "@/request/pay_payermax";
import { mapState } from 'vuex';

export default {
  name: "TopupView",
  components: {
    TopupLogin,
    HeaderMenu,
    FootComp,
    AlertMsg,
    CheckOrder,
    LoadingData,
    CheckinReward,
    TabComp
  },
  provide() {
    return {
      onCheckIn: this.onCheckIn
    }
  },
  data() {
    return {
      tabs: [],
      timezone: 'Asia/Shanghai',
      ip: ''
    }
  },
  computed: {
    // 当前 URL 中携带的参数的键值对
    currentUrlParams() {
      let url = new URL(window.location.href).search
      return withdrawParamsToObj(url);
    },

    isSandbox(){
      const hash = window.location.hash; // 获取哈希部分
      return hash.includes('sandbox');
    },

    pointShop(){
      // return this.isSandbox;
      return true;
    },

    // app id
    appid() {
      return this.$store.state.appid;
    },
    
    ...mapState({
          isLogin: state => state.isLogin
      })
  },
  watch: {
    isLogin(newVal, oldVal){
      if (newVal){
        this.getCheckInUserInfo();
      }
    }
  },
  mounted() {
    if (this.pointShop){
      this.tabs = [{title: 'center.TOPUP', component: 'main-content', data: {}},
      {title: 'center.PointsShop', component: 'point-shop', data: {}},
      {title: 'center.Events', component: 'checkin-list', data: {}}
    ];
      this.tabs[1].data.sandbox = this.isSandbox;
    } else {
      this.tabs = [{title: 'center.TOPUP', component: 'main-content', data: {}}];
    }
    this.getCountry();
    this.checkOrderByParams(this.currentUrlParams);   // 检查是否需要显示弹窗(是否是支付成功之后的展示界面)
  },
  methods: {
    onCheckIn(){
      console.log('here on check in');
      this.tabs[2].data.isChecked = true;
      this.getCheckInUserInfo();
    },
    async getCheckInConfig(){
      const userMsg = this.$store.state.userMsg;
      if (!this.pointShop){
        return;
      }
      const resp = await axios({
        method: 'POST',
        url: 'https://webpay.ivymobile.com/check_in_config',
        data:{
          appid: userMsg.appid || basicJson.appid
        }
      });
      resp.data.timezone = this.timezone;
      resp.data.ip = this.ip;
      if (this.tabs[2].data.isChecked === undefined){
        resp.data.today = 0;
        resp.data.checkDay = 0;
        this.tabs[2].data = resp.data;
      } else {
        this.tabs[2].data = Object.assign(this.tabs[2].data, resp.data);
      }
    },
    async getCheckInUserInfo(){
      if (!this.pointShop){
        return;
      }
      const userMsg = this.$store.state.userMsg;
      if (!userMsg.appid){
        return;
      }
      const resp = await axios({
        method: 'POST',
        url: 'https://webpay.ivymobile.com/check_in_user_info',
        data:{
          appid: userMsg.appid || basicJson.appid,
          userId: userMsg.userId,
          date: new Date().toISOString().slice(0, 10)
        }
      });
      this.tabs[2].data.today = resp.data.daily;
      this.tabs[2].data.isChecked = resp.data.daily_check;
      this.tabs[2].data.checkDay = resp.data.cumulative;
      if (resp.data.daily_check === false){
        this.$refs.checkinReward.showDialog(this.tabs[2].data);
      }
    },
    async getItemList(country){
      const userMsg = this.$store.state.userMsg;
      try{
        this.$store.commit("isLoadCurrent", true);
        const resp = await axios({
          method: 'POST',
          url: 'https://webpay.ivymobile.com/',
          data:{
            appid: userMsg.appid || basicJson.appid,
            action: "app_country_config",
            country
          }
        });
        const {amountList, pointAmountList} = resp.data;
        // this.goodsListJSON = amountList;
        // this.onAmountList(amountList);
        this.tabs[0].data.goodsData = amountList;
        if (this.pointShop){
          this.tabs[1].data.items = pointAmountList;
        }
        this.$store.commit("isLoadCurrent", false);
      } catch (err){
        console.log(err);
        this.$store.commit("isLoadCurrent", false);
        this.$store.commit('changeAlertMsg', err.message?err.message:`Network error, please wait for a while and try again.`);
      }
    },
    async getCountry(){
      try{
        const response = await axios({
            method: "GET",
            timeout: 5000,
            url: 'https://ipinfo.io/json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        const {country, timezone, ip } = response.data;
        // const country = "NL"
        console.log('country ' + country + ' ' + timezone);
        this.timezone = timezone;
        this.ip = ip;
        this.tabs[0].data.country = country;
        if (this.pointShop)
          this.tabs[1].data.country = country;
        this.getItemList(country);
      } catch (error){
        console.log(error);
        this.tabs[0].data.country = "US";
        if (this.pointShop)
          this.tabs[1].data.country = "US";
        this.getItemList("US");
      }
      this.getCheckInConfig();
    },

    checkOrder(){
      const order = localStorage.getItem("order");
      if (order){
        const { order, paytype, uid, appid } = JSON.parse(order);
        switch(paytype){
          case "airwallex":
            this.payWithAirwallex(order);
            break;
          case "xsolla":
            this.payWithXsolla(order);
            break;
          case "payermax":
            this.payWithPayerMax(order);
            break;
          case "paypal":
            this.payWithPayPal(order);
            break;
        }
      }
    },
    // xsolla 参数格式为：?user_id=123456&status=done&invoice_id=1402701702
    // paypal 参数格式为：?token=3VB45790YF982110M&PayerID=Z7WHWYPK5TGFJ
    // 根据当前的 URL 去查询当前界面是否是支付完成之后跳转的界面，如果是，则分情况查询订单支付状态
    checkOrderByParams(paramsObj) {
      if (Object.keys(paramsObj).length && !paramsObj.giftcode) {  // 如果带参数，也就是当前界面并不是付款后重定向的内容
        localStorage.setItem("ivyUrlParams", JSON.stringify(paramsObj));
        location.href = "/";
        return;
      } else {    // 如果不携带参数，就将其存储到 localstorage 中
        const localIvy = localStorage.getItem("ivyUrlParams");
        if (!localIvy) { return; }
        paramsObj = JSON.parse(localIvy);
        if (!Object.keys(paramsObj).length) { return; }         // 如果本地不存在参数，说明非第一次重定向的内容
        localStorage.setItem("ivyUrlParams", JSON.stringify({}));       // 清空本地的内容
      }
      const keys = Object.keys(paramsObj);    // 获取参数中所有的 key 值形成数组
      if (keys.includes("user_id") && keys.includes("status") && keys.includes("invoice_id")) {     // 如果是 paypal 重定向
        this.checkOrderXsolla(paramsObj);
      } else if (keys.includes("token") && keys.includes("PayerID")) {    // 如果是 xsolla 的重定向
        this.checkOrderPaypal(paramsObj);
      } else if (keys.includes("id") && keys.includes("type")) {        // 如果是 airwallex 支付方式
        this.checkOrderAirwallex(paramsObj);
      } else if (keys.includes("token") && keys.length === 1) {       // 如果是 PayPal 取消支付的跳转界面
        this.cancelOrder();
      } else if (keys.includes('outTradeNo')){
        this.checkOrderPayerMax(paramsObj);
      } else if (keys.includes("txid") && keys.includes("cs")) {      // 如果是 ariwallex 的第三方支付 paysafecard 支付方式
        this.checkOrderAirwallex(paramsObj);
      } else if (keys.includes("error") && keys.length === 3) {    // 支付过期的重定向界面(还包括 keys.includes("id") && keys.includes("type")，但是会被前面的内容所覆盖)
        this.cancelOrder();
      }

    },

    checkOrderPayerMax(paramsObj){
      const ivyData = JSON.parse(localStorage.getItem("ivyMergeNeverlandUserMsg"));
      const goodsPackDetail = ivyData.goodsList;
      const uid = ivyData.userId;
      checkOrderResultPayerMax(uid, paramsObj["outTradeNo"], this.isSandbox, goodsPackDetail).then(res => {
        const data = res.data;    // 反馈的数据
        const statusBack = res.status;    // 返回的状态码
        if (!statusBack) { return this.$store.commit("changeAlertMsg", `${data}`); }    // 弹出错误提示
        const orderStatus = data.status;
        if (orderStatus === "SUCCESS") {    // 如果订单成功
          this.$store.commit("alertCheckOrder", "Payment successful! The purchased items have been sent to your recharge account. Please check within the game. For easier access and a better experience, please add to Home Screen.");
        } else {
          this.cancelOrder();
        }
      });
    },

    // 如果是 xsolla 订单支付完成之后
    checkOrderXsolla(paramsObj) {
      const { user_id, status } = paramsObj;    // 从URL 的参数中获取(用户 ID)
      const goodsSku = paramsObj["invoice_id"];   // 商品 SKU 计费点
      if ( !user_id || !goodsSku || !status) { return; }   // 如果存在参数为空(可能是用户破解系统)，则不进行处理
      const orderId = JSON.parse(localStorage.getItem("ivyMergeNeverlandUserMsg"))["orderId"];
      const goodsPackDetail = JSON.parse(localStorage.getItem("ivyMergeNeverlandUserMsg"))["goodsDetail"];
      checkOrderResultWithXsolla(user_id, orderId, goodsPackDetail, this.isSandbox).then(res => {
        const data = res.data;    // 反馈的数据
        const statusBack = res.status;    // 返回的状态码
        if (!statusBack) { this.$store.commit("changeAlertMsg", data); }    // 弹出错误提示

        const orderStatus = data.status;    // 订单状态
        if (orderStatus === "done" || orderStatus === "paid") {
          this.$store.commit("alertCheckOrder", "Payment successful! The purchased items have been sent to your recharge account. Please check within the game. For easier access and a better experience, please add to Home Screen.");
        } else {
          this.cancelOrder();
        }
      })
     },

    // 如果是 PayPal 支付完成之后
    checkOrderPaypal(paramsObj) {
      const { token, PayerID } = paramsObj;     // 获取查询订单的参数
      if (!token || !PayerID) { return; }   // 如果存在一个参数为空，则不进行处理

      checkPaypalOrderStatus(token, this.isSandbox).then(res => {
        let { status, data } = res;
        if (!status) { this.$store.commit("changeAlertMsg", data); }  // 如果获取内容错误，就直接弹窗显示
        data = typeof data === "string"?JSON.parse(data):data;
        const statusOrder = data["status"];

        if (statusOrder === "APPROVED") {
          this.$store.commit("alertCheckOrder", "Payment successful! The purchased items have been sent to your recharge account. Please check within the game. For easier access and a better experience, please add to Home Screen.");
        } else if (statusOrder === "PAYER_ACTION_REQUIRED"){
          openUrl('https://www.paypal.com/checkoutnow?token=' + data['id'], true);
        } else {
          this.cancelOrder();
        }
        // 显示弹窗
      }).catch(error => {
        console.error("error");
        console.error(error);
      })
   },

    // 如果是 Airwallex 支付完成之后
    checkOrderAirwallex(paramsObj) {
      // const { id, type } = paramsObj;
      const { id } = paramsObj;
      let orderId = id;
      if (!id) {              // 如果是 airwallex 的第三方支付(不在重定向的界面中携带订单 ID)
        const ivyObjData = JSON.parse(localStorage.getItem("ivyMergeNeverlandUserMsg"));
        orderId = ivyObjData["airwallexOrderId"];
      }
      // else {          // 如果是 airwallex 本身的支付类型
      //   if (type !== "SUCCESS_URL") { return this.$store.commit("changeAlertMsg", "Payment failed, Please try again!");  }          // 如果是付款成功后的界面
      // }
      checkOrderResultAirwallex(orderId, this.isSandbox).then(res => {
        const { status, data } = res;
        if (!status) { return this.$store.commit("changeAlertMsg", data); }
        const { orderStatus } = data;
        if (!orderStatus) {
          return this.$store.commit("changeAlertMsg", "Order not completed");
        }
        if (orderStatus === "SUCCEEDED") {    // 如果订单成功
          this.$store.commit("alertCheckOrder", "Payment successful! The purchased items have been sent to your recharge account. Please check within the game. For easier access and a better experience, please add to Home Screen.");
        } else {
          this.cancelOrder();
        }
      })
    },

    // 取消支付的弹窗提示
    cancelOrder() {
      this.$store.commit("alertCheckOrder", {
        isSuccess: false,
        checkResultMsg: "Payment unsuccessful. The payment process has been suspended, please place an order again. For easier access and a better experience, please add to Home Screen."
      })
    },
  }
}
</script>

<style scoped lang="scss">
  #topup_view {
    background-color: #eff1f6;
    font-family: Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,PingFang SC,Noto Sans,Microsoft Yahei,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    //padding-bottom: 30px;
    .bigBgcImage {
      img {
        width: 100%;
        max-height: 200px;
        height: auto;
      }
    }
  }

  .content {
    position: relative;
    max-width: 1100px;
    // padding: 20px 24px;
    margin: auto;
    margin-top: 20px;
    // border-radius: 12px;
    // background-color: #fff;
    // box-shadow: 0 2px 7px 0 rgba(0,0,0,.15);
  }

  @media screen and (max-width: 800px) {
    .bigBgcImage {
      display: none;
    }
  }
</style>