<template>
    <card-comp class="tabs">
      <!-- Tab 标题 -->
      <ul class="tab-titles">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: selectedTab === index }"
          @click="tabChange(index)">
          {{ $t(tab.title) }}
        </li>
      </ul>
  
      <!-- Tab 内容 -->
      <div class="tab-content">
        <div
        v-for="(tab, index) in tabs"
        :key="index"
        v-show="selectedTab === index">
          <component :is="tab.component" :data="tab.data"></component>
        </div>
      </div>
      <!-- 底部 -->
       <div class="TopCenterTabPhone">
        <div class="tab-box">
          <div v-for="(tab, index) in tabs" :key="index" class="item" :class="{ itemActive: selectedTab === index }" @click="tabChange(index)">
            <svg v-if="index === 0 && selectedTab !== index" data-v-ad8b192e="" xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
              <rect data-v-ad8b192e="" width="22" height="22" transform="translate(0.5)" fill="white"></rect>
              <path data-v-ad8b192e="" fill-rule="evenodd" clip-rule="evenodd" d="M4.73519 2.71453C4.67382 2.76509 4.61809 2.8233 4.56954 2.88839L1.46094 7.05548H9.77824L4.73519 2.71453ZM1.49396 8.43048L10.4282 19.7302C10.4585 19.7685 10.4909 19.8039 10.5251 19.8365L10.5251 8.43048H1.49396ZM11.9001 19.8365C11.9344 19.804 11.9668 19.7685 11.9971 19.7302L20.9314 8.43048H11.9001L11.9001 19.8365ZM20.9644 7.05548L17.9491 3.01345L13.2533 7.05548H20.9644ZM16.4538 2.48633L11.5158 6.73687L6.57775 2.48633L16.4538 2.48633Z" fill="#BABABA"></path>
              <path data-v-ad8b192e="" fill-rule="evenodd" clip-rule="evenodd" d="M4.73519 2.71453C4.67382 2.76509 4.61809 2.8233 4.56954 2.88839L1.46094 7.05548H9.77824L4.73519 2.71453ZM1.49396 8.43048L10.4282 19.7302C10.4585 19.7685 10.4909 19.8039 10.5251 19.8365L10.5251 8.43048H1.49396ZM11.9001 19.8365C11.9344 19.804 11.9668 19.7685 11.9971 19.7302L20.9314 8.43048H11.9001L11.9001 19.8365ZM20.9644 7.05548L17.9491 3.01345L13.2533 7.05548H20.9644ZM16.4538 2.48633L11.5158 6.73687L6.57775 2.48633L16.4538 2.48633Z" fill="white" fill-opacity="0.2"></path>
            </svg>
            <svg v-if="index === 0 && selectedTab === index" data-v-ad8b192e="" xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
              <rect data-v-ad8b192e="" width="22" height="22" transform="translate(0.5)" fill="white"></rect>
              <path data-v-ad8b192e="" fill-rule="evenodd" clip-rule="evenodd" d="M4.73519 2.71453C4.67382 2.76509 4.61809 2.8233 4.56954 2.88839L1.46094 7.05548H9.77824L4.73519 2.71453ZM1.49396 8.43048L10.4282 19.7302C10.4585 19.7685 10.4909 19.8039 10.5251 19.8365L10.5251 8.43048H1.49396ZM11.9001 19.8365C11.9344 19.804 11.9668 19.7685 11.9971 19.7302L20.9314 8.43048H11.9001L11.9001 19.8365ZM20.9644 7.05548L17.9491 3.01345L13.2533 7.05548H20.9644ZM16.4538 2.48633L11.5158 6.73687L6.57775 2.48633H16.4538Z" fill="#4B69BA"></path>
            </svg>
            <svg v-if="index === 1 && selectedTab !== index" data-v-ad8b192e="" xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
              <path data-v-ad8b192e="" d="M18.2564 4.24344C16.4509 2.43797 14.0527 1.44434 11.5001 1.44434C8.94758 1.44434 6.54941 2.43797 4.74393 4.24344C2.93846 6.04892 1.94482 8.44709 1.94482 10.9997C1.94482 13.5522 2.93846 15.9504 4.74393 17.7559C6.54941 19.5613 8.94758 20.555 11.5001 20.555C14.0527 20.555 16.4509 19.5613 18.2564 17.7559C20.0618 15.9504 21.0555 13.5522 21.0555 10.9997C21.0555 8.44709 20.0618 6.04892 18.2564 4.24344ZM16.5978 10.138L14.6852 12.0032L15.136 14.6355C15.1933 14.9742 15.0588 15.3079 14.7799 15.5096C14.623 15.6242 14.4387 15.6814 14.2544 15.6814C14.1125 15.6814 13.9705 15.6466 13.8385 15.5768L11.4727 14.3342L9.10695 15.5768C8.80314 15.7362 8.44204 15.7113 8.16562 15.5096C7.88919 15.3079 7.75223 14.9717 7.8095 14.6355L8.26274 12.0008L6.35018 10.1355C6.10364 9.89645 6.01648 9.54531 6.12356 9.21908C6.23065 8.89285 6.50707 8.65876 6.84575 8.61145L9.48797 8.22794L10.6709 5.83226C10.8228 5.52346 11.1291 5.3342 11.4727 5.3342C11.8164 5.3342 12.1227 5.52595 12.2746 5.83226L13.4575 8.22794L16.0997 8.61145C16.4384 8.66125 16.7173 8.89285 16.8219 9.22157C16.9315 9.54531 16.8443 9.89645 16.5978 10.138Z" fill="#BABABA"></path>
            </svg>
            <svg v-if="index === 1 && selectedTab === index" data-v-ad8b192e="" xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
              <path data-v-ad8b192e="" d="M18.2564 4.24344C16.4509 2.43797 14.0527 1.44434 11.5001 1.44434C8.94758 1.44434 6.54941 2.43797 4.74393 4.24344C2.93846 6.04892 1.94482 8.44709 1.94482 10.9997C1.94482 13.5522 2.93846 15.9504 4.74393 17.7559C6.54941 19.5613 8.94758 20.555 11.5001 20.555C14.0527 20.555 16.4509 19.5613 18.2564 17.7559C20.0618 15.9504 21.0555 13.5522 21.0555 10.9997C21.0555 8.44709 20.0618 6.04892 18.2564 4.24344ZM16.5978 10.138L14.6852 12.0032L15.136 14.6355C15.1933 14.9742 15.0588 15.3079 14.7799 15.5096C14.623 15.6242 14.4387 15.6814 14.2544 15.6814C14.1125 15.6814 13.9705 15.6466 13.8385 15.5768L11.4727 14.3342L9.10695 15.5768C8.80314 15.7362 8.44204 15.7113 8.16562 15.5096C7.88919 15.3079 7.75223 14.9717 7.8095 14.6355L8.26274 12.0008L6.35018 10.1355C6.10364 9.89645 6.01648 9.54531 6.12356 9.21908C6.23065 8.89285 6.50707 8.65876 6.84575 8.61145L9.48797 8.22794L10.6709 5.83226C10.8228 5.52346 11.1291 5.3342 11.4727 5.3342C11.8164 5.3342 12.1227 5.52595 12.2746 5.83226L13.4575 8.22794L16.0997 8.61145C16.4384 8.66125 16.7173 8.89285 16.8219 9.22157C16.9315 9.54531 16.8443 9.89645 16.5978 10.138Z" fill="#4B69BA"></path>
            </svg>
            <svg v-if="index === 2 && selectedTab !== index" data-v-ad8b192e="" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path data-v-ad8b192e="" d="M19.3427 21.141C19.895 21.141 20.3427 20.6933 20.3427 20.141V11.5391C20.3427 10.9868 19.895 10.5391 19.3427 10.5391H6.32324C5.77096 10.5391 5.32324 10.9868 5.32324 11.5391V20.141C5.32324 20.6933 5.77096 21.141 6.32324 21.141H19.3427Z" fill="#BABABA" stroke="#BABABA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path data-v-ad8b192e="" d="M12.833 21.141V10.5391" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path>
              <path data-v-ad8b192e="" d="M5.07432 12.2178L20.4409 12.2178" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path>
              <path data-v-ad8b192e="" d="M21.0576 6.55078H4.6084C4.05611 6.55078 3.6084 6.9985 3.6084 7.55078V9.3252C3.6084 9.87748 4.05611 10.3252 4.6084 10.3252H21.0576C21.6099 10.3252 22.0576 9.87748 22.0576 9.3252V7.55078C22.0576 6.9985 21.6099 6.55078 21.0576 6.55078Z" fill="#BABABA" stroke="#BABABA" stroke-width="2" stroke-linejoin="round"></path>
              <path data-v-ad8b192e="" d="M8.54395 2.8584L12.8327 6.53975L17.1215 2.8584" stroke="#BABABA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <svg v-if="index === 2 && selectedTab === index" data-v-ad8b192e="" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path data-v-ad8b192e="" d="M19.3427 21.141C19.895 21.141 20.3427 20.6933 20.3427 20.141V11.5391C20.3427 10.9868 19.895 10.5391 19.3427 10.5391H6.32324C5.77096 10.5391 5.32324 10.9868 5.32324 11.5391V20.141C5.32324 20.6933 5.77096 21.141 6.32324 21.141H19.3427Z" fill="#4B69BA" stroke="#4B69BA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path data-v-ad8b192e="" d="M12.833 21.141V10.5391" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path>
              <path data-v-ad8b192e="" d="M5.07432 12.2178L20.4409 12.2178" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path>
              <path data-v-ad8b192e="" d="M21.0576 6.55078H4.6084C4.05611 6.55078 3.6084 6.9985 3.6084 7.55078V9.3252C3.6084 9.87748 4.05611 10.3252 4.6084 10.3252H21.0576C21.6099 10.3252 22.0576 9.87748 22.0576 9.3252V7.55078C22.0576 6.9985 21.6099 6.55078 21.0576 6.55078Z" fill="#4B69BA" stroke="#4B69BA" stroke-width="2" stroke-linejoin="round"></path>
              <path data-v-ad8b192e="" d="M8.54395 2.8584L12.8327 6.53975L17.1215 2.8584" stroke="#4B69BA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            {{ $t(tab.title) }}
          </div>
        </div>
       </div>
    </card-comp>
  </template>
  
  <script>
  import CardComp from "@/components/CardComp";
  export default {
    data() {
      return {
        selectedTab: 0
      };
    },
    components: {
        CardComp
    },
    props: {
        tabs: {
            type: Array,
            required: true
        }
    },
    watch: {
      '$route'(to, from) {
        this.init();
      },
    },
    mounted() {
      this.init();
    },
    methods:{
      init(){
        const parsedUrl = window.location.href;
        if(parsedUrl.includes('/PointsShop')){
          this.selectedTab = 1
        }else if(parsedUrl.includes('/Events')){
          this.selectedTab = 2
        }else{
          this.selectedTab = 0
        }
      },
      tabChange(index){
        this.selectedTab = index
        const routes = { 0: '/topup', 1: '/PointsShop', 2: '/Events'};
        const targetPath = routes[index]
        this.$router.push(targetPath);
      }
    }
  };
  </script>
  
  <style scoped>
  .tab-titles {
    display: flex;
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #e4e4e4;
  }
  
  .tab-titles li {
    padding: 10px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 400;
  }
  
  .tab-titles li.active {
    border-bottom: 3px solid #557be1;
    font-weight: 700;
  }
  
  .tab-content {
    margin-top: 20px;
  }
  .TopCenterTabPhone{
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    
    background: #fff;
    box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, .12);
    z-index: 2;
    color: #333;
    font-weight: 400;
    padding: 0.6rem 0;
    .tab-box{
      display: flex;
      align-items: center;
    }
    .item {
        width: 33%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 0;
        position: relative;
        margin-right: 0;
        border-right: 1px solid #eee;
        color: #bababa;
        font-size: 12px;
    }
    .item:last-child {
        border-right: none;
    }
    .itemActive {
        font-weight: 700;
        border-bottom: none;
        color: #4b69ba;
    }
  }
  @media screen and (max-width: 800px) {
    .tab-titles {
      display: none;
    }
    .TopCenterTabPhone{
      display: block;
    }
  }
  </style>
  