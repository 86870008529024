import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import Home from "@/views/home/index.vue"
import TopupView from "@/views/topup/TopupView";
import GdprView from "@/views/gdpr/GdprView";
import PrivacyPolicy from "@/views/privacy_policy/PrivacyPolicy";
import UserAgreement from "@/views/user_agreement/UserAgreement";

const routes = [
    {
        path: '/',
        // component: Home,
        redirect: '/topup',
    },
    {
        path: '/sandbox',
        redirect: '/topup',
    },
    {
        path: '/topup',
        component: TopupView,
    },
    {
        path: '/PointsShop',
        component: TopupView,
    },
    {
        path: '/Events',
        component: TopupView,
    },
    {
        path: "/gdpr",
        component: GdprView
    },
    {
        path: "/PrivacyPolicy",
        component: PrivacyPolicy
    },
    {
        path: '/UserAgreement',
        component: UserAgreement
    }
]

const router = createRouter({
    history: createWebHashHistory(),    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    routes,     // `routes: routes` 的缩写
})

export default router;